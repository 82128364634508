// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  graphQlUri: 'https://move2one.gyselroth.net/graphql',
  grApiUrl: 'https://apistage.gyselroth.net/graphql/',
  msalgraph: 'https://graph.microsoft.com/v1.0',
  authConfig: {
    // IAuthConfig
  },
  oidcConfig: {
    // AuthConfig from 'angular-oauth2-oidc';
    issuer: 'https://accountsstage.tam.ch',

    // The SPA's id. The SPA is registerd with this id at the auth-server
    // clientId: 'server.code',
    clientId: 'BbFivK09KxbuYk6_Y1BPrQ',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
