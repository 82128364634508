import { DOCUMENT } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, APP_INITIALIZER, NgZone, NgModule } from '@angular/core';
import { __decorate } from 'tslib';
import { fromEvent } from 'rxjs';
import { applyPolyfills, defineCustomElements } from '@gyselroth/gricons/dist/loader/index.js';

/* eslint-disable */
const _c0 = ["*"];
const proxyInputs = (Cmp, inputs) => {
  const Prototype = Cmp.prototype;
  inputs.forEach(item => {
    Object.defineProperty(Prototype, item, {
      get() {
        return this.el[item];
      },
      set(val) {
        this.z.runOutsideAngular(() => this.el[item] = val);
      }
    });
  });
};
const proxyMethods = (Cmp, methods) => {
  const Prototype = Cmp.prototype;
  methods.forEach(methodName => {
    Prototype[methodName] = function () {
      const args = arguments;
      return this.z.runOutsideAngular(() => this.el[methodName].apply(this.el, args));
    };
  });
};
const proxyOutputs = (instance, el, events) => {
  events.forEach(eventName => instance[eventName] = fromEvent(el, eventName));
};
const defineCustomElement = (tagName, customElement) => {
  if (customElement !== undefined && typeof customElements !== 'undefined' && !customElements.get(tagName)) {
    customElements.define(tagName, customElement);
  }
};
// tslint:disable-next-line: only-arrow-functions
function ProxyCmp(opts) {
  const decorator = function (cls) {
    const {
      defineCustomElementFn,
      inputs,
      methods
    } = opts;
    if (defineCustomElementFn !== undefined) {
      defineCustomElementFn();
    }
    if (inputs) {
      proxyInputs(cls, inputs);
    }
    if (methods) {
      proxyMethods(cls, methods);
    }
    return cls;
  };
  return decorator;
}
let GrIcon = class GrIcon {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
GrIcon.ɵfac = function GrIcon_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || GrIcon)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
GrIcon.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: GrIcon,
  selectors: [["gr-icon"]],
  inputs: {
    icon: "icon",
    lazy: "lazy",
    name: "name",
    size: "size",
    src: "src"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function GrIcon_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
GrIcon = __decorate([ProxyCmp({
  defineCustomElementFn: undefined,
  inputs: ['icon', 'lazy', 'name', 'size', 'src']
})], GrIcon);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GrIcon, [{
    type: Component,
    args: [{
      selector: 'gr-icon',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['icon', 'lazy', 'name', 'size', 'src']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
const DIRECTIVES = [GrIcon];
let didInitialize = false;
const raf = h => {
  if (typeof __zone_symbol__requestAnimationFrame === 'function') {
    return __zone_symbol__requestAnimationFrame(h);
  }
  if (typeof requestAnimationFrame === 'function') {
    return requestAnimationFrame(h);
  }
  return setTimeout(h);
};
const appInitialize = (doc, zone) => {
  return () => {
    const win = doc.defaultView;
    if (win && typeof window !== 'undefined') {
      if (didInitialize) {
        console.warn('Gricons Angular was already initialized. Make sure GriconsAngularModule.forRoot() is just called once.');
      }
      didInitialize = true;
      const aelFn = '__zone_symbol__addEventListener' in doc.body ? '__zone_symbol__addEventListener' : 'addEventListener';
      return applyPolyfills().then(() => {
        return defineCustomElements(win, {
          exclude: [],
          syncQueue: true,
          raf,
          jmp: h => zone.runOutsideAngular(h),
          ael(elm, eventName, cb, opts) {
            elm[aelFn](eventName, cb, opts);
          },
          rel(elm, eventName, cb, opts) {
            elm.removeEventListener(eventName, cb, opts);
          }
        });
      });
    }
  };
};
class GriconsAngularModule {
  static forRoot() {
    return {
      ngModule: GriconsAngularModule,
      providers: [{
        provide: APP_INITIALIZER,
        useFactory: appInitialize,
        multi: true,
        deps: [DOCUMENT, NgZone]
      }]
    };
  }
}
GriconsAngularModule.ɵfac = function GriconsAngularModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || GriconsAngularModule)();
};
GriconsAngularModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: GriconsAngularModule
});
GriconsAngularModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GriconsAngularModule, [{
    type: NgModule,
    args: [{
      declarations: [...DIRECTIVES],
      exports: [...DIRECTIVES]
    }]
  }], null, null);
})();

/*
 * Public API Surface of gricons-angular
 */

/**
 * Generated bundle index. Do not edit.
 */

export { DIRECTIVES, GrIcon, GriconsAngularModule };
