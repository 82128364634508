import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Error = {
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type ForbiddenError = Error & {
  __typename?: 'ForbiddenError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type Link = {
  __typename?: 'Link';
  id: Scalars['Int'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type LinkCreateResult = Link | UnauthorizedError;

export type LinkDeleteResult = ForbiddenError | NotFoundError | Response | UnauthorizedError;

export type LinkInput = {
  name: Scalars['String'];
  url: Scalars['String'];
};

export type LinkUpdateResult = ForbiddenError | Link | NotFoundError | UnauthorizedError;

export type Mail = {
  __typename?: 'Mail';
  id: Scalars['String'];
  isUnread: Scalars['Boolean'];
  preview?: Maybe<Scalars['String']>;
  sender: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  timestamp: Scalars['Int'];
};

export type MailResult = Mail | ServiceUnavailableError | UnauthorizedError;

export type Moodle = {
  __typename?: 'Moodle';
  displayName: Scalars['String'];
  id: Scalars['Int'];
  summary: Scalars['String'];
};

export type MoodleResult = Moodle | ServiceUnavailableError | UnauthorizedError;

export type Mutation = {
  __typename?: 'Mutation';
  linkCreate: LinkCreateResult;
  /** @deprecated Unused */
  linkDelete: LinkDeleteResult;
  linkUpdate: LinkUpdateResult;
  tagUpdate: TagUpdateResult;
  tileCreate: TileCreateResult;
  tileDelete: TileDeleteResult;
  tileUpdate: TileUpdateResult;
  tileUsageIncrement: TileUsageIncrementResult;
};


export type MutationLinkCreateArgs = {
  link: LinkInput;
};


export type MutationLinkDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationLinkUpdateArgs = {
  id: Scalars['Int'];
  link: LinkInput;
};


export type MutationTagUpdateArgs = {
  id: Scalars['Int'];
  tag: TagInput;
};


export type MutationTileCreateArgs = {
  tile: TileInput;
};


export type MutationTileDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationTileUpdateArgs = {
  id: Scalars['Int'];
  tile: TileInput;
};


export type MutationTileUsageIncrementArgs = {
  id: Scalars['Int'];
};

export type NavigationItem = {
  __typename?: 'NavigationItem';
  iconName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  /** @deprecated Unused */
  organization: Organization;
};

export type NavigationItemResult = NavigationItem | UnauthorizedError;

export type NotFoundError = Error & {
  __typename?: 'NotFoundError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  /** @deprecated Unused */
  defaultNavigationItem?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** Returns NULL if the user is not authenticated */
  privateLogo?: Maybe<Scalars['String']>;
  providers: Array<Provider>;
  publicLogo: Scalars['String'];
  shortName: Scalars['String'];
  url: Scalars['String'];
};

export type OrganizationFilterInput = {
  shortName: Scalars['String'];
};

export type OrganizationResult = NotFoundError | Organization | UnauthorizedError;

export type Provider = {
  __typename?: 'Provider';
  clientId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  issuer?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  scope?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  mails: Array<MailResult>;
  moodle: Array<MoodleResult>;
  navigationItems: Array<NavigationItemResult>;
  /** Returns the organization with the specified URL (if not authenticated) or the organization of the logged in user */
  organization: OrganizationResult;
  tags: Array<TagResult>;
  /** Returns the tiles of the specified organization (if not authenticated) or the tiles of the logged in user and organization */
  tiles: Array<TileResult>;
};


export type QueryOrganizationArgs = {
  filter?: InputMaybe<OrganizationFilterInput>;
};


export type QueryTilesArgs = {
  filter?: InputMaybe<TileFilterInput>;
};

export type Response = {
  __typename?: 'Response';
  success: Scalars['Boolean'];
};

export type ServiceUnavailableError = Error & {
  __typename?: 'ServiceUnavailableError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type Tag = {
  __typename?: 'Tag';
  iconName: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** Default, Custom or Overwritten */
  type: Scalars['String'];
};

export type TagInput = {
  name: Scalars['String'];
};

export type TagResult = Tag | UnauthorizedError;

export type TagUpdateResult = NotFoundError | Tag | UnauthorizedError;

export type Tile = {
  __typename?: 'Tile';
  id: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  /** @deprecated Unused */
  isGlobal: Scalars['Boolean'];
  isUnlocked?: Maybe<Scalars['Boolean']>;
  isVisible: Scalars['Boolean'];
  isVisibleOnMobile: Scalars['Boolean'];
  links: Array<Link>;
  name: Scalars['String'];
  /** Returns NULL if the user is not authenticated */
  tags: Array<Tag>;
  /** Default, Overwritten, Custom or Global */
  type: Scalars['String'];
  /** Returns the number of all uses or that of the logged in user */
  used: Scalars['Int'];
};

export type TileCreateResult = NotFoundError | Tile | UnauthorizedError;

export type TileDeleteResult = ForbiddenError | NotFoundError | Response | UnauthorizedError;

export type TileFilterInput = {
  organizationId: Scalars['Int'];
};

export type TileInput = {
  isVisible?: InputMaybe<Scalars['Boolean']>;
  isVisibleOnMobile?: InputMaybe<Scalars['Boolean']>;
  linkIds?: InputMaybe<Array<Scalars['Int']>>;
  name: Scalars['String'];
  tagIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type TileResult = NotFoundError | Tile | UnauthorizedError;

export type TileUpdateResult = ForbiddenError | NotFoundError | Tile | UnauthorizedError;

export type TileUsageIncrementResult = NotFoundError | Response | UnauthorizedError;

export type UnauthorizedError = Error & {
  __typename?: 'UnauthorizedError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type LinkCreateMutationVariables = Exact<{
  link: LinkInput;
}>;


export type LinkCreateMutation = { __typename?: 'Mutation', linkCreate: { __typename: 'Link', id: number } | { __typename: 'UnauthorizedError', code: number, message: string } };

export type LinkUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  link: LinkInput;
}>;


export type LinkUpdateMutation = { __typename?: 'Mutation', linkUpdate: { __typename: 'ForbiddenError', code: number, message: string } | { __typename: 'Link', id: number } | { __typename: 'NotFoundError', code: number, message: string } | { __typename: 'UnauthorizedError', code: number, message: string } };

export type MailsQueryVariables = Exact<{ [key: string]: never; }>;


export type MailsQuery = { __typename?: 'Query', mails: Array<{ __typename: 'Mail', id: string, sender: string, subject?: string | null, preview?: string | null, isUnread: boolean, timestamp: number } | { __typename: 'ServiceUnavailableError', code: number, message: string } | { __typename: 'UnauthorizedError', code: number, message: string }> };

export type MoodleQueryVariables = Exact<{ [key: string]: never; }>;


export type MoodleQuery = { __typename?: 'Query', moodle: Array<{ __typename: 'Moodle', id: number, displayName: string, summary: string } | { __typename: 'ServiceUnavailableError', code: number, message: string } | { __typename: 'UnauthorizedError', code: number, message: string }> };

export type NavigationItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type NavigationItemsQuery = { __typename?: 'Query', navigationItems: Array<{ __typename: 'NavigationItem', id: number, name: string, iconName?: string | null } | { __typename: 'UnauthorizedError', code: number, message: string }> };

export type OrganizationQueryVariables = Exact<{
  filter?: InputMaybe<OrganizationFilterInput>;
}>;


export type OrganizationQuery = { __typename?: 'Query', organization: { __typename: 'NotFoundError', code: number, message: string } | { __typename: 'Organization', id: number, name: string, shortName: string, url: string, publicLogo: string, privateLogo?: string | null } | { __typename: 'UnauthorizedError', code: number, message: string } };

export type TagUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  tag: TagInput;
}>;


export type TagUpdateMutation = { __typename?: 'Mutation', tagUpdate: { __typename: 'NotFoundError', code: number, message: string } | { __typename: 'Tag', id: number, name: string, type: string } | { __typename: 'UnauthorizedError', code: number, message: string } };

export type TagsQueryVariables = Exact<{ [key: string]: never; }>;


export type TagsQuery = { __typename?: 'Query', tags: Array<{ __typename: 'Tag', id: number, name: string, iconName: string, type: string } | { __typename: 'UnauthorizedError', code: number, message: string }> };

export type TileCreateMutationVariables = Exact<{
  tile: TileInput;
}>;


export type TileCreateMutation = { __typename?: 'Mutation', tileCreate: { __typename: 'NotFoundError', code: number, message: string } | { __typename: 'Tile', id: number, name: string, image?: string | null, isVisible: boolean, isVisibleOnMobile: boolean, type: string, links: Array<{ __typename?: 'Link', id: number, name: string, url: string }>, tags: Array<{ __typename?: 'Tag', iconName: string, id: number }> } | { __typename: 'UnauthorizedError', code: number, message: string } };

export type TileDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TileDeleteMutation = { __typename?: 'Mutation', tileDelete: { __typename: 'ForbiddenError', code: number, message: string } | { __typename: 'NotFoundError', code: number, message: string } | { __typename: 'Response', success: boolean } | { __typename: 'UnauthorizedError', code: number, message: string } };

export type TileUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  tile: TileInput;
}>;


export type TileUpdateMutation = { __typename?: 'Mutation', tileUpdate: { __typename: 'ForbiddenError', code: number, message: string } | { __typename: 'NotFoundError', code: number, message: string } | { __typename: 'Tile', id: number, name: string, image?: string | null, isVisible: boolean, isVisibleOnMobile: boolean, type: string, links: Array<{ __typename?: 'Link', id: number, name: string, url: string }>, tags: Array<{ __typename?: 'Tag', iconName: string, id: number }> } | { __typename: 'UnauthorizedError', code: number, message: string } };

export type TileUsageIncrementMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TileUsageIncrementMutation = { __typename?: 'Mutation', tileUsageIncrement: { __typename: 'NotFoundError', code: number, message: string } | { __typename: 'Response', success: boolean } | { __typename: 'UnauthorizedError', code: number, message: string } };

export type TilesQueryVariables = Exact<{
  filter?: InputMaybe<TileFilterInput>;
}>;


export type TilesQuery = { __typename?: 'Query', tiles: Array<{ __typename: 'NotFoundError', code: number, message: string } | { __typename: 'Tile', id: number, name: string, image?: string | null, isVisible: boolean, isVisibleOnMobile: boolean, type: string, used: number, isUnlocked?: boolean | null, links: Array<{ __typename?: 'Link', id: number, name: string, url: string }>, tags: Array<{ __typename?: 'Tag', iconName: string, id: number }> } | { __typename: 'UnauthorizedError', code: number, message: string }> };

export const LinkCreateDocument = gql`
    mutation linkCreate($link: LinkInput!) {
  linkCreate(link: $link) {
    __typename
    ... on Link {
      id
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LinkCreateGQL extends Apollo.Mutation<LinkCreateMutation, LinkCreateMutationVariables> {
    document = LinkCreateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LinkUpdateDocument = gql`
    mutation linkUpdate($id: Int!, $link: LinkInput!) {
  linkUpdate(id: $id, link: $link) {
    __typename
    ... on Link {
      id
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LinkUpdateGQL extends Apollo.Mutation<LinkUpdateMutation, LinkUpdateMutationVariables> {
    document = LinkUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MailsDocument = gql`
    query mails {
  mails {
    __typename
    ... on Mail {
      id
      sender
      subject
      preview
      isUnread
      timestamp
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MailsGQL extends Apollo.Query<MailsQuery, MailsQueryVariables> {
    document = MailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MoodleDocument = gql`
    query moodle {
  moodle {
    __typename
    ... on Moodle {
      id
      displayName
      summary
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MoodleGQL extends Apollo.Query<MoodleQuery, MoodleQueryVariables> {
    document = MoodleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NavigationItemsDocument = gql`
    query navigationItems {
  navigationItems {
    __typename
    ... on NavigationItem {
      id
      name
      iconName
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NavigationItemsGQL extends Apollo.Query<NavigationItemsQuery, NavigationItemsQueryVariables> {
    document = NavigationItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganizationDocument = gql`
    query organization($filter: OrganizationFilterInput) {
  organization(filter: $filter) {
    __typename
    ... on Organization {
      id
      name
      shortName
      url
      publicLogo
      privateLogo
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationGQL extends Apollo.Query<OrganizationQuery, OrganizationQueryVariables> {
    document = OrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TagUpdateDocument = gql`
    mutation tagUpdate($id: Int!, $tag: TagInput!) {
  tagUpdate(id: $id, tag: $tag) {
    __typename
    ... on Tag {
      id
      name
      type
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TagUpdateGQL extends Apollo.Mutation<TagUpdateMutation, TagUpdateMutationVariables> {
    document = TagUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TagsDocument = gql`
    query tags {
  tags {
    __typename
    ... on Tag {
      id
      name
      iconName
      type
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TagsGQL extends Apollo.Query<TagsQuery, TagsQueryVariables> {
    document = TagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TileCreateDocument = gql`
    mutation tileCreate($tile: TileInput!) {
  tileCreate(tile: $tile) {
    __typename
    ... on Tile {
      id
      name
      image
      isVisible
      isVisibleOnMobile
      type
      links {
        id
        name
        url
      }
      tags {
        iconName
        id
      }
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TileCreateGQL extends Apollo.Mutation<TileCreateMutation, TileCreateMutationVariables> {
    document = TileCreateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TileDeleteDocument = gql`
    mutation tileDelete($id: Int!) {
  tileDelete(id: $id) {
    __typename
    ... on Response {
      success
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TileDeleteGQL extends Apollo.Mutation<TileDeleteMutation, TileDeleteMutationVariables> {
    document = TileDeleteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TileUpdateDocument = gql`
    mutation tileUpdate($id: Int!, $tile: TileInput!) {
  tileUpdate(id: $id, tile: $tile) {
    __typename
    ... on Tile {
      id
      name
      image
      isVisible
      isVisibleOnMobile
      type
      links {
        id
        name
        url
      }
      tags {
        iconName
        id
      }
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TileUpdateGQL extends Apollo.Mutation<TileUpdateMutation, TileUpdateMutationVariables> {
    document = TileUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TileUsageIncrementDocument = gql`
    mutation tileUsageIncrement($id: Int!) {
  tileUsageIncrement(id: $id) {
    __typename
    ... on Response {
      success
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TileUsageIncrementGQL extends Apollo.Mutation<TileUsageIncrementMutation, TileUsageIncrementMutationVariables> {
    document = TileUsageIncrementDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TilesDocument = gql`
    query tiles($filter: TileFilterInput) {
  tiles(filter: $filter) {
    __typename
    ... on Tile {
      id
      name
      image
      isVisible
      isVisibleOnMobile
      type
      used
      isUnlocked
      links {
        id
        name
        url
      }
      tags {
        iconName
        id
      }
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TilesGQL extends Apollo.Query<TilesQuery, TilesQueryVariables> {
    document = TilesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }